// WARNING: DO NOT EDIT. This file is automatically
// generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-west-2',
  aws_cognito_identity_pool_id: 'us-west-2:c7739dfb-d9c7-42f0-b4d8-883e6f4ccb38',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_CV5agA0J8',
  aws_user_pools_web_client_id: '6qqhqntmol7a44hijv9qal9nle',
  federationTarget: 'COGNITO_USER_POOLS',
  oauth: {
    domain: 'frontce3c3190-ce3c3190-test.auth.us-west-2.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'http://bobbyBing.com/google',
    redirectSignOut: 'http://bobbyDing.com:8081/signIn/',
    responseType: 'code',
  },
};


export default awsmobile;
